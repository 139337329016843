/* src/IndexPage.css */
.index-page {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url('../public/images/hospital.jpeg') no-repeat center center/cover;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(146, 146, 146, 0.7); /* White color with some transparency */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1; /* Asegúrate de que el overlay esté detrás del enlace de Ayuda */
}

.top-bar {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    box-sizing: border-box;
    z-index: 2; /* Asegúrate de que la barra superior esté por encima del overlay */
}

.logo {
    height: 70px; /* Ajusta el tamaño del logo según sea necesario */
}

.help-link {
    z-index: 2; /* Asegúrate de que el enlace de Ayuda esté por encima del overlay */
}

.content {
    text-align: center;
}


.title {
    font-size: 36px;
    margin-bottom: 10px;
    color: rgb(255, 255, 255);
    font-weight: bold; /* Negritas */
}

.subtitle {
    font-size: 24px;
    margin-bottom: 20px;
    color: rgb(255, 255, 255);
}

.button {
    background-color: #001B58;
    color: white;
    padding: 15px 30px; /* Ajusta el tamaño del botón */
    font-size: 18px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: darkblue;
}
