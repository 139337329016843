/* GestorManager.css */

.gestor-container {
    padding: 20px;
  }
  
  .export-button {
    background-color: #365b77;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .export-button:hover {
    background-color: #7498b6;
  }
  
  .historico-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .historico-table th, .historico-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .historico-table th {
    background-color: #f4f4f4;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination-button {
    background-color: #365b77;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin: 0 10px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .pagination-button:hover {
    background-color: #7498b6;
  }
  
  .pagination-button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination-info {
    font-size: 16px;
    color: #333;
    font-weight: 600;
  }
  .section-separator {
    margin: 2rem 0; /* Ajusta el margen según tus necesidades */
  }  

/* Estilo para los títulos de las secciones */
.section-title {
    background-color: #365b77;
    color: #ffffff; /* Color del texto en blanco para contraste */
    padding: 10px; /* Ajusta el padding según sea necesario */
    border-radius: 5px; /* Opcional: para esquinas redondeadas */
    margin-bottom: 1rem; /* Espacio debajo del título */
  }
  
  /* Estilo para la tabla y el encabezado */
  .historico-table thead th {
    background-color: #365b77; /* Color de fondo para el encabezado de la tabla */
    color: #ffffff; /* Color del texto en blanco para contraste */
    padding: 10px; /* Ajusta el padding según sea necesario */
    
  }
  
  /* Separador */
  .section-separator {
    border-top: 1px solid #d1d5db; /* Color de la línea divisoria */
    margin: 2rem 0; /* Ajusta el margen según sea necesario */
  }
  /* Contenedor principal para las tarjetas */
.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
}

/* Estilo básico para la tarjeta */
.card {
  background-color: #f0f8ff;
  border: 2px solid #365b77;
  border-radius: 10px; /* Esquinas ligeramente redondeadas */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 12px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 150px; /* Ajuste de altura mínima */
  max-width: 100%;
}

/* Efecto de hover para las tarjetas */
.card:hover {
  transform: translateY(-8px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Título de la tarjeta */
.card-title {
  font-size: 1.1em;
  font-weight: bold;
  color: #365b77;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

/* Icono antes del texto */
.card-title .icon {
  margin-right: 6px; /* Espacio entre el ícono y el texto */
}

/* Tabla dentro de la tarjeta */
.historico-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 8px;
}

.historico-table th,
.historico-table td {
  border: 1px solid #ddd;
  padding: 6px; /* Ajuste de padding */
  text-align: left;
}

.historico-table th {
  background-color: #7498b6;
  color: white;
}

.historico-table tr:nth-child(even) {
  background-color: #e6f2ff;
}

.historico-table tr:hover {
  background-color: #d1e0ff;
}

/* Botón de exportar */
.export-button {
  background-color: #365b77;
  color: white;
  padding: 8px 16px; /* Ajuste de padding */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 12px;
}

.export-button:hover {
  background-color: #7498b6;
}
.card {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  cursor: pointer;
}

.historico-table {
  width: 100%;
  border-collapse: collapse;
}

.historico-table th, .historico-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  background-color: #365b77;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
.view-toggle {
  display: flex;
  margin-bottom: 10px;
}

.view-toggle button {
  padding: 10px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  flex: 1;
  text-align: center;
}

.view-toggle button.active {
  background-color: #365b77;
  color: white;
}

.historico-table {
  width: 100%;
  border-collapse: collapse;
}

.historico-table th, .historico-table td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

.card {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.card-title {
  font-size: 18px;
  cursor: pointer;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.pagination button {
  padding: 8px 16px;
  border: none;
  background-color: #365b77;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.export-button {
  margin-bottom: 20px;
  padding: 10px 20px;
  border: none;
  background-color: #365b77;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}
.card {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.card.expanded {
  height: auto;
}

.card-content {
  display: none;
}

.card-content.expanded {
  display: block;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
}

.view-toggle {
  display: flex;
  margin-bottom: 10px;
}

.view-toggle button {
  padding: 10px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  flex: 1;
  text-align: center;
}

.view-toggle button.active {
  background-color: #365b77;
  color: white;
}

.historico-table {
  width: 100%;
  border-collapse: collapse;
}

.historico-table th, .historico-table td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.pagination button {
  padding: 8px 16px;
  border: none;
  background-color: #365b77;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.export-button {
  margin-bottom: 20px;
  padding: 10px 20px;
  border: none;
  background-color: #365b77;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}
.pagination-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.pagination-buttons button {
  padding: 8px 12px;
  background-color: #365b77;
  color: white;
  border: none;
  cursor: pointer;
}

.pagination-buttons button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.filter-container {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}
.filter-container label {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
}
.filter-container button {
  align-self: center;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.filter-container button:hover {
  background-color: #0056b3;
}


